/* App.css */

.App {
  text-align: center;
  height: 100vh;
  overflow: hidden;
}

.App-gif {
  width: auto;
  max-width: 100%;
  height: auto;
  max-height: 100%;
  object-fit: contain;
  transition: opacity 2s ease;
}

.App-gif.hide {
  opacity: 0;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
  background-color: #282c34;
}

.App-header.fade-in {
  opacity: 1;
}

.App-header.loading {
  background-color: #000;

}

.App-link {
  color: #fff;
  background-color: #000;
  padding: 5px 10px;
  border-radius: 4px;
}

.welcome-text {
  opacity: 1;
  transition: opacity 2s ease;
  color: #fff;
  text-align: center;
  margin-bottom: 30px;
}
