.button {
  background-color: red;
  color: #fff;
  padding: 8px 16px;
  font-size: 1rem;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  max-width: 100%;
  box-sizing: border-box;
}

.button:hover {
  background-color: black;
}
