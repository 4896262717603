/* RedLine.css */

.red-line {
  width: 5px;
  height: 10px;
  background-color: red;
  position: absolute;
  top: 0;
  left: 0;
  animation: expandAndHold 2s ease-in-out forwards;

  @media (max-width: 768px) {
    visibility: hidden;
  }
}

.red-line:after {
  content: '';
  display: block;
  width: 10px;
  height: 100%;
  background-color: red;
  position: absolute;
  top: 0;
  left: 90%;
  animation: expandVertical 1s ease-in-out forwards;
  animation-delay: 2s;

  @media (min-width: 789px) and (max-width: 1440px) {
    left: 95%;
  }

  @media (max-width: 768px) {
    visibility: hidden;
  }
}

@keyframes expandAndHold {
  0% {
    width: 0;
  }

  100% {
    width: 100%;
  }
}

@keyframes expandVertical {
  0% {
    height: 0;
  }

  100% {
    height: 100vh;
  }
}
