/* Page.css */

.page {
  width: 100%;
  min-height: 100vh;
  background-color: #282c34;
  position: absolute;
  top: 0px;
  left: 0;
  overflow-y: auto;
}

.page button {
  background: none;
  border: none;
  color: white;
  text-decoration: underline;
  cursor: pointer;
  margin-right: 90%;
  font-size: 20px;
  text-align: left;
}

.page button:hover {
  text-decoration-color: red;
}
